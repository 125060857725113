// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-blogs-js": () => import("/opt/build/repo/src/templates/news-blogs.js" /* webpackChunkName: "component---src-templates-news-blogs-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customers-js": () => import("/opt/build/repo/src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-features-js": () => import("/opt/build/repo/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("/opt/build/repo/src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-proteo-enterprise-js": () => import("/opt/build/repo/src/pages/proteo-enterprise.js" /* webpackChunkName: "component---src-pages-proteo-enterprise-js" */),
  "component---src-pages-proteo-fleet-js": () => import("/opt/build/repo/src/pages/proteo-fleet.js" /* webpackChunkName: "component---src-pages-proteo-fleet-js" */),
  "component---src-pages-proteo-insights-js": () => import("/opt/build/repo/src/pages/proteo-insights.js" /* webpackChunkName: "component---src-pages-proteo-insights-js" */),
  "component---src-pages-proteo-lite-js": () => import("/opt/build/repo/src/pages/proteo-lite.js" /* webpackChunkName: "component---src-pages-proteo-lite-js" */),
  "component---src-pages-proteo-mobile-js": () => import("/opt/build/repo/src/pages/proteo-mobile.js" /* webpackChunkName: "component---src-pages-proteo-mobile-js" */),
  "component---src-pages-resources-js": () => import("/opt/build/repo/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

